import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button, Form, Row, Col, FormControl } from 'react-bootstrap';
import TextInput from '../../../Controls/textInput';
import { ItemGroupSelector, ItemLocationSelector, ItemTypeSelector, ItemLocationsTreeControlSelector } from '../../itemselectors';
import './iteminfo.css';

import ItemInputUnitSelection from '../ItemInputUnitSelection';
import InventoryDashCheckbox from '../../../Controls/InventoryDashCheckbox';
import { ItemSummary } from '../ItemSummary';
import { LabelTags } from '../../../labelTags/LabelTags';

import { useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import {
    fetchItemTags,
  } from "../../../../store/actions/itemTagActions";

function ItemInfo({
    item,
    onInputValueChanged,
    selectUnitType,
    unitMeasureTypes,
    updateUnitSelection,
    selectedTab,
    setSelectedTab,
    keyPressed,
    readOnly,
    locationTreeOpened,
    setLocationTreeOpened, 
    setItemLinkLabelItems,
    isEditing
}) {


    // var tags = [];
    
    // const tags = [
    //     {name: 'dog', id: 1},
    //     {name: 'cat', id: 2},
    //     {name: 'pig', id: 3},
    //     {name: 'bat', id: 4},
    //     {name: 'bag', id: 5},
    //     {name: 'caterpillar', id: 6} 
    //     ];

    const itemTags = useSelector((state) => state.itemtags.itemtags);
    const loading = useSelector((state) => state.itemtags.loading);
    const itemsLoaded = useSelector((state) => state.itemtags.itemsLoaded);
    const leftColWid = 7;
    const rightColWid = 5;
    const [forceRender, setForceRender] = useState(false);
    const nameRef = useRef(null);
    //label tags that are not currently existing and newly added to the item
    const [newlyAddedTags, setNewlyAddedTags] = useState([]);
    //label tags that are currently in the db, which are added to an item
    const [linkedTags, setLinkedTags] = useState([]);

    const locationRef = useRef();



    const dispatch = useDispatch();
        
    useEffect(() => {
        if (!itemsLoaded) {
            fetchData();
        }
    }, [itemsLoaded, dispatch]);

    function originalFetchData() {
        trackPromise(dispatch(fetchItemTags()));
    };

    const fetchData = useCallback(
        debounce((q) => originalFetchData(), 200),
        []
    );


    useEffect(() => { //focuses first input field after tab is clicked
        if (selectedTab === "item" || selectedTab === "") {
            nameRef.current.focus();
        }
    }, [selectedTab]);

    
    useEffect(() => { 
        setItemLinkLabelItems(newlyAddedTags, linkedTags);
    }, [newlyAddedTags, linkedTags]);

    function groupValueChanged(groupid, groupname) {
        item.groupId = groupid;
        item.groupName = groupname;
        setForceRender(!forceRender);
    }
    function typeValueChanged(typeid, typename) {
        item.typeId = typeid;
        item.typeName = typename;
        setForceRender(!forceRender);
    }
    function locationValueChanged(locationid, locationname) {
        item.locationId = locationid;
        item.locationName = locationname;
        setForceRender(!forceRender);
    }

    // public enum MeasurementTypes
    // {
    //     Length = 0,
    //     Width = 1,
    //     Height = 2,
    //     Weight = 3,
    //     Volume = 4,
    //     Quantity = 5,
    //     UnitCountType =6
    // }

    //if locationTree is opened, modal will scroll down
    if (locationTreeOpened) {
        locationRef.current.scrollIntoView();
    }


    return (

        <div className="item_modal_container">
            <div 
                className="item-form-container" 
                // onKeyDown={keyPressed}
                >
            {/* 11/11/20 <Form autoComplete={'off'} id="itemInfoForm" name={'item_info_edit_form'} noValidate validated={validated} > */}

            <Row className="add_edit_item_row" xs={1} md={2}>
                <Col className="item_add_edit_col noselect">

                    <TextInput
                        name="itemName"
                        label="Name"
                        value={item.itemName}
                        onChange={onInputValueChanged}
                        required={true}
                        validatefailtext={'You must enter a name for this item.'}
                        inputref={nameRef}
                        readOnly={readOnly}
                    />
                </Col>
                <Col className="item_add_edit_col noselect">
                    <ItemInputUnitSelection
                        lbltext="Quantity"
                        name={"quantity"}
                        itemvalue={item?.quantity}
                        unitTypeValue={item?.quantityUnitType}
                        onInputValueChanged={onInputValueChanged}
                        unittypename={"quantityUnitType"}
                        unittypebasemt={6}
                        unitMeasureTypes={unitMeasureTypes}
                        updateUnitSelection={updateUnitSelection}
                        required={true}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>

            {/* Item Groups Component */}
            <Row className="add_edit_item_row" xs={1} md={2}>
                <Col className="item_add_edit_col noselect">
                    <ItemGroupSelector
                        valueChanged={groupValueChanged}
                        value={item?.groupId}
                        readOnly={readOnly}
                    />
                </Col>
                {/* Location Component  */}
                <Col className="item_add_edit_col noselect" ref={locationRef}>
                <ItemLocationsTreeControlSelector
                        valueChanged={locationValueChanged}
                        value={item?.locationId}
                        readOnly={readOnly}
                        showLocationSelection={locationTreeOpened}
                        setShowLocationSelection={setLocationTreeOpened}
                    />
                </Col>
            </Row>

            
            <Row className="add_edit_item_row" xs={1} md={2}>
                <Col className="item_add_edit_col item_label_col noselect">
                <label>Label Tags</label>
                    <LabelTags
                    allTags={itemTags}
                    item={item}
                    setNewlyAddedTags={setNewlyAddedTags}
                    newlyAddedTags={newlyAddedTags}
                    linkedTags={linkedTags}
                    setLinkedTags={setLinkedTags}
                    />

                    {/* 
                        <ItemLocationSelector
                        valueChanged={locationValueChanged}
                        value={item.locationId}
                        /> */}
                </Col>
                <Col className="item_add_edit_col noselect">
                    <ItemTypeSelector
                        valueChanged={typeValueChanged}
                        value={item?.typeId}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>

            {/* Barcode and Resale */}
            <Row className="add_edit_item_row" xs={1} md={2}>
                <Col className="item_add_edit_col noselect">
                    <TextInput
                        name="barcode"
                        label="Barcode"
                        onChange={onInputValueChanged}
                        value={item?.barcode}
                        readOnly={readOnly}
                    />
                </Col>
                <Col className="item_add_edit_col noselect">
                    <ItemInputUnitSelection
                        lbltext="Resale Price"
                        name={"resalePrice"}
                        itemvalue={item.resalePrice}
                        unitTypeValue={item.resalePriceUnitType}
                        onInputValueChanged={onInputValueChanged}
                        unittypename={"resalePriceUnitType"}
                        unittypebasemt={6}
                        unitMeasureTypes={unitMeasureTypes}
                        updateUnitSelection={updateUnitSelection}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>

            {/* Item Type Component */}
            <Row className="item_taxable" xs={1} md={2}>

                <Col className="item_add_edit_col noselect">
                    {/* 11/11/20 <Form.Check name="taxable" label="Taxable" defaultChecked={item.taxable} value={item.taxable} onClick={onInputValueChanged} /> */}
                    <InventoryDashCheckbox
                        checked={item?.taxable}
                        onChange={onInputValueChanged}
                        label={'Taxable'}
                        name={'taxable'}
                        disabled={readOnly}
                    />
                </Col>
            </Row>

          {isEditing ? (
               <div className="item_summary">
               <ItemSummary
                   item={item}
               />
          </div>
          ) : <></>}

            {/* </Form> */}
        </div>
        </div>
        
    )
};

export { ItemInfo };