import React, { useState, useEffect } from 'react';
import './labeltags.css';

export function LabelTags({ allTags, item, valueChanged, newlyAddedTags, setNewlyAddedTags, linkedTags, setLinkedTags }) {

    const [tags, setTags] = useState([]);
    const [displayTags, setDisplayTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [initialTags, setInitialTags] = useState([]);
    const [updatedInitialTags, setUpdatedInitialTags] = useState([]);

    useEffect(() => {
        findMatchingTags();
    }, [item, allTags]);

    const alertUniqueTag = 'That tag is already used. Please enter a unique tag.';
    const alertInvalid = 'Tags must be letters or numbers only. Please remove invalid characters.';

    function returnTagArray(e) {
        let enteredTag = e.target.value;
        let matchingTags = [];

        for (let i = 0; i < allTags.length; i++) {
            if (allTags[i].name.includes(enteredTag)) {
                matchingTags.push(allTags[i].name);
            }
        }
        setDisplayTags(matchingTags);
    }

    function insertTag(event) {
        const element = event.target;
        const tag = element.innerText;
        const tagToExclude = tag;

        if (updatedInitialTags.length > 0) {
            for (let i = 0; i < updatedInitialTags.length; i++) {
                if (updatedInitialTags[i].name === tag) {
                    setUpdatedInitialTags([...updatedInitialTags.filter(tag => tag !== tagToExclude)]);
                    setTagInput('');
                    setTags([...tags, tag]);
                    return;
                }
            }
        }

        if (isDuplicateTag(tag)) {
            return alert(alertUniqueTag);
        }

        setTags([...tags, tag]);

        for (let i = 0; i < allTags.length; i++) {
            if (allTags[i].name === tag) {
                setLinkedTags([...linkedTags, allTags[i].id]);
            }
        }

        setTagInput('');
        setDisplayTags([]);
    }

    function handleKeyDown(e) {
        setTagInput(e.value);

        const value = e.target.value.trim();

        if (e.key !== 'Enter') return;

        if (!/^[a-zA-Z0-9]+$/.test(value)) {
            alert(alertInvalid);
            return;
        }

        if (isDuplicateTag(value)) {
            alert(alertUniqueTag);
            return;
        }

        let matchFound = false;
        const uniqueTags = [...newlyAddedTags];

        for (const tag of allTags) {
            if (tag.name === value) {
                matchFound = true;
                let updatedArray = linkedTags;
                updatedArray.push(tag.id);
                setLinkedTags(updatedArray);
                break;
            }
        }

        if (!matchFound) {
            uniqueTags.push(value);
        }

        setNewlyAddedTags(uniqueTags);
        setTags([...tags, value]);
        e.target.value = '';
    }

    function isDuplicateTag(tag) {
        if (tags.includes(tag)) {
            return true;
        }

        for (let i = 0; i < initialTags.length; i++) {
            if (initialTags[i].name === tag) {
                return true;
            }
        }

        return false;
    }

    function removeNewTag(index, tag) {
        setTags(tags.filter((el, i) => i !== index));

        for (let i = 0; i < newlyAddedTags.length; i++) {
            if (newlyAddedTags.includes(tag)) {
                let updatedArray = newlyAddedTags.filter(item => item !== tag);
                setNewlyAddedTags(updatedArray);
                return;
            }
        }

        for (let i = 0; i < allTags.length; i++) {
            if (allTags[i].name === tag) {
                let linkedIndex = allTags[i].id;
                let updatedArray = linkedTags.filter(item => item !== linkedIndex);
                setLinkedTags(updatedArray);
                return;
            }
        }
    }

    function findMatchingTags() {
        if (item.linkedLabelTags && allTags) {
            setUpdatedInitialTags(initialTags);
            const linkedLabelTags = item.linkedLabelTags;
            const allTagsArray = allTags;

            const matches = allTagsArray.filter(tag =>
                linkedLabelTags.includes(tag.id)
            );

            if (matches.length > 0) {
                setInitialTags(prevTags => [...prevTags, ...matches]);
            }
        }
    }

    function removeExistingTag(index, tag) {
        setUpdatedInitialTags(updatedTags => [...updatedTags, tag]);
        setTags(tags.filter((t, i) => i !== index));
        setInitialTags(initialTags.filter((t, i) => i !== index));
    }

    return (
        <>
            <div className=''>
                {initialTags.map((tag, index) => (
                    <div className='tag-item' key={index}>
                         <span className="">{tag.name + ' '}</span>
                         <span className="close" onClick={() => removeExistingTag(index, tag)}>&times;</span>
                    </div>
                ))}
                {tags.map((tag, index) => (
                    <div className="tag-item" key={index}>
                        <span className="text" id={'tagElement'} value={tag}>{tag}</span>
                        <span className="close" onClick={() => removeNewTag(index, tag)}>&times;</span>
                    </div>
                ))}
                <input id='tagInput' onKeyDown={handleKeyDown} onChange={returnTagArray} type="text" className="tags-input" placeholder="" value={tagInput} />
            </div>
            <div id='tagList' className='expanded_tag_container'>
                <ul>
                    {displayTags.map((x, index) =>
                        <li key={index} className='' onClick={insertTag}>{x}</li>
                    )}
                </ul>
            </div>
        </>
    );
}
